import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Typography } from 'antd';
import queryString from 'query-string';
import React, { useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { updateHolisticProjectAction } from '../../../../redux-store/holistic-projects-store';
import settings from '../../../../settings';
import useQueryParams from '../../../../shared/use-query-params';
import HolisticSteps from '../HolisticSteps';
import {
  extractPublicHolisticProject,
  publicHolisticProjectQuery,
} from '../constants';
import ProjectBanner from '../step-2-details/ProjectBanner';

const { Title, Paragraph, Text } = Typography;

const queryId = 'sponsorAProject';

function SponsorAProjectPeoples({ step, title, projectId }) {
  const { loading: projectLoading, refetch } = useOneM1({
    variables: { _id: projectId },
    query: publicHolisticProjectQuery,
    extract: extractPublicHolisticProject,
    queryId,
    fetchPolicy: 'network-only',
    updateAction: updateHolisticProjectAction,
    skip: !projectId,
    showError: true,
  });
  const project = useSingleSomethingA10('holisticProjects', projectId, queryId);

  const peopleGroups = (project && project.peopleGroups) || [];

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const queryParams = useQueryParams();

  const handleFinish = useCallback(
    (values) => {
      setLoading(true);
      const link = `/partner/sponsor-a-project/${projectId}/redirect?${queryString.stringify(
        {
          ...values,
          ...queryParams,
        },
      )}`;
      history.push(link);
    },
    [projectId, history, queryParams],
  );
  return (
    <>
      <ProjectBanner project={project} loading={projectLoading} />
      <HolisticSteps step={step} title={title} />
      <div>
        <Title level={4} style={{ textAlign: 'center' }}>
          Thank you for committing to give!
        </Title>
        <Paragraph style={{ textAlign: 'center', fontSize: 16 }}>
          Your donation will give you access to quarterly reports and
          testimonies about the Unreached People Groups impacted by your
          selected project.
        </Paragraph>
        <Paragraph style={{ textAlign: 'center', fontSize: 16 }}>
          Will you allow God to deepen your heart connection to these Unreached
          People Groups by committing to pray for them regularly? AIMS calls
          this <Text style={{ fontWeight: 500 }}>ADOPTING</Text> Unreached
          People Groups.
        </Paragraph>
        <Title level={3} style={{ textAlign: 'center' }}>
          {peopleGroups.length > 1
            ? 'Unreached People Groups that will be impacted'
            : 'Unreached People Group that will be impacted'}
        </Title>
        <div className="pg-circles">
          {peopleGroups.map((pg) => (
            <div key={pg._id}>
              <div className="pg-circle">
                <div className="pg-circle-initial">
                  {pg.nameAcrossCountries[0]}
                </div>
                <img
                  className="pg-photo"
                  src={pg.primaryThumbnailUrl}
                  alt={pg.nameAcrossCountries}
                />
              </div>
              <div className="pg-text">{pg.nameAcrossCountries}</div>
            </div>
          ))}
        </div>
        <Form
          layout="vertical"
          form={form}
          onFinish={handleFinish}
          style={{ textAlign: 'center' }}
        >
          <Form.Item
            name="commitToAdopt"
            initialValue={true}
            valuePropName="checked"
          >
            <Checkbox
              disabled={loading}
              style={{ fontSize: 16, letterSpacing: 1.25 }}
            >
              {peopleGroups.length > 1
                ? `Yes, I will ADOPT these Unreached People Groups.`
                : `Yes, I will ADOPT this Unreached People Group.`}
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Link
                to={`/partner/sponsor-a-project/${projectId}${location.search}`}
                style={{ marginLeft: -12 }}
              >
                <Button
                  type="text"
                  icon={<ArrowLeftOutlined />}
                  disabled={loading}
                >
                  Back
                </Button>
              </Link>
              <Button
                type="primary"
                htmlType="submit"
                icon={<ArrowRightOutlined />}
                loading={loading}
              >
                Proceed to Payment
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
      <style jsx>{`
        .pg-circle {
          border-radius: 50%;
          overflow: hidden;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          background: ${settings.colors.holisticGreen};
          height: 160px;
          width: 160px;
          margin-right: 16px;
          margin-left: 16px;
          margin-bottom: 8px;
          border: 3px solid ${settings.colors.holisticLightGreen};
        }
        .pg-circles {
          display: flex;
          justify-content: center;
          margin-right: -16px;
          margin-left: -16px;
          margin-bottom: 16px;
        }
        .pg-circle-initial {
          color: white;
          font-size: 24px;
        }
        .pg-photo {
          object-fit: cover;
          position: absolute;
          height: 160px;
          width: 160px;
          top: 0;
          left: 0;
        }
        .pg-text {
          font-size: 18px;
          font-weight: 500;
          text-align: center;
        }
      `}</style>
    </>
  );
}

export default SponsorAProjectPeoples;
