import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import { EyeOutlined } from '@ant-design/icons';
import { Button, Card, List, Typography } from 'antd';
import React, { useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import settings from '../../settings';
import {
  AgreementStatuses,
  allLoanAgreementsQuery,
  extractLoanAgreements,
} from '../loan-agreements/constants';

const { Text, Title, Paragraph } = Typography;

function SingleLoanAgreement({ projectId, loanAgreement, locale }) {
  const updated = `Updated ${new Intl.DateTimeFormat(locale, {
    dateStyle: 'full',
  }).format(new Date(loanAgreement.updatedAt))}`;
  const status = AgreementStatuses[loanAgreement.status];
  let signDate =
    loanAgreement.parties &&
    loanAgreement.parties.reduce((prev, curr) => {
      if (curr.date && (prev === undefined || prev < new Date(curr.date))) {
        return new Date(curr.date);
      }
      return prev;
    }, undefined);
  signDate =
    signDate &&
    `Signed ${new Intl.DateTimeFormat(locale, {
      dateStyle: 'full',
    }).format(signDate)}`;
  return (
    <Card bodyStyle={{ position: 'relative' }}>
      <Title level={4} style={{ margin: 0 }}>
        Holistic Project Loan Agreement
      </Title>
      <div
        style={{
          fontSize: 14,
          color: settings.colors.textGray,
          marginBottom: 4,
        }}
      >{`Version ${loanAgreement.version}`}</div>
      <div
        style={{
          fontSize: 16,
          fontWeight: 600,
          color: status && settings.colors[status.color],
        }}
      >
        {status && status.label}
      </div>
      {signDate ? (
        <div style={{ marginBottom: 8 }}>{signDate}</div>
      ) : (
        <div style={{ marginBottom: 8 }}>{updated}</div>
      )}
      <Title level={5} style={{ marginBottom: 4 }}>
        Signed By
      </Title>
      <div>
        {loanAgreement.parties &&
          loanAgreement.parties.map((p) => (
            <div style={{ fontSize: 12 }} key={p._id}>{`${p.name} - ${
              p.contactName || 'Not Signed'
            }`}</div>
          ))}
      </div>
      <div style={{ position: 'absolute', top: 16, right: 16 }}>
        <Link
          to={`/holistic-projects/${projectId}/loan-agreement/view/${loanAgreement._id}`}
          style={{ marginRight: 8 }}
        >
          <Button icon={<EyeOutlined />}>View</Button>
        </Link>
      </div>
    </Card>
  );
}

const queryId = 'projectLoanAgreementTab';

function ProjectLoanAgreementsTab({ project, projectId }) {
  const [sortedBy, setSortedBy] = useState('updatedAt');
  const [sortOrder, setSortOrder] = useState('DESC');
  const filters = useRef({
    isTemplate: false,
    projectId,
  });
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);

  const {
    error,
    loading,
    data: loanAgreements,
    search: loanAgreementsSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allLoanAgreementsQuery,
    extract: extractLoanAgreements,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId,
    skip: !projectId,
  });

  const locale = useSelector((store) => store.locale, shallowEqual);

  return (
    <div
      style={{
        maxWidth: 800,
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingTop: 16,
      }}
    >
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 1,
          lg: 1,
          xl: 1,
          xxl: 1,
        }}
        loading={loading}
        dataSource={loanAgreements}
        renderItem={(item) => {
          return (
            <List.Item>
              <SingleLoanAgreement
                loanAgreement={item}
                locale={locale}
                projectId={project._id}
              />
            </List.Item>
          );
        }}
        locale={{
          emptyText: (
            <>
              <Paragraph style={{ fontSize: 14, marginBottom: 24 }}>
                {`This project doesn't have any loan agreements yet.`}
              </Paragraph>
            </>
          ),
        }}
        rowKey="_id"
      />
    </div>
  );
}

export default ProjectLoanAgreementsTab;
