import { updateReportAction } from '@aims/shared/redux-store/reports-store';
import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import { gql } from '@apollo/client';
import {
  ReportAttachmentAttributes,
  ReportPeopleGroupAttributes,
  ReportUserLongAttributes,
  ReportUserShortAttributes,
} from '../../reports/constants';

const HolisticProposalFullAttributes = gql`
  {
    _id
    contactId
    contact {
      _id
      fullName
    }
    regionCode
    regionName
    reportType
    publicAttachments ${ReportAttachmentAttributes}
    privateAttachments ${ReportAttachmentAttributes}
    notes
    submittedAt
    createdBy
    createdByUser ${ReportUserShortAttributes}
    isSubmitted
    submittedByUser ${ReportUserShortAttributes}
    isApproved
    status
    updatedBy
    createdAt
    updatedAt
    deletedBy
    deletedAt
    approval {
      status
      when
      approver ${ReportUserLongAttributes}
    }
    peopleGroupIds
    peopleGroups ${ReportPeopleGroupAttributes}
    onBehalfOfName
    onBehalfOfId
    holisticProjectId
    projectName
    typeOfProject
    desc
    vision
    goals
    currency
    exchangeRate
    loanTotalUsd
    loanTotalForeign
    loanTerm
    setupExpensesDesc
    recurringMonthlyExpensesForeign
    recurringMonthlyExpensesDesc
    recurringMonthlyRevenueForeign
    recurringMonthlyRevenueDesc
    supportedFieldWorkers {
      contactId
      contact {
        _id
        fullName
      }
      fieldWorkerName
      amountForeign
    }
    otherProfitUses
    loanDetails
    marketAnalysis
    riskAnalysis
    locationRisk
    responsiblePersons {
      contactId
      contact {
        _id
        fullName
      }
      name
      role
      projectedHrsPerWeek
      priorExperience
    }
    canEdit
  }
`;

const query = gql`
  query HolisticProposalForUser($_id: ID!) {
    holisticProposalForUser(_id: $_id) ${HolisticProposalFullAttributes}
  }
`;

const extract = (data) => data?.holisticProposalForUser;

const HolisticProposalUpdateAttributes = gql`
{
  crud
  id
  new ${HolisticProposalFullAttributes}
}
`;

export const holisticProposalUpdatesSubscription = gql`
  subscription HolisticProposalUpdatesForUser($_id: ID!) {
    holisticProposalUpdatesForUser(_id: $_id) ${HolisticProposalUpdateAttributes}
  }
`;

function useReport(_id, queryId = 'useHolisticProposal', showError = true) {
  const { loading, refetch } = useOneM1({
    variables: { _id },
    query,
    extract,
    fetchPolicy: 'network-only',
    updateAction: updateReportAction,
    queryId,
    showError,
  });
  const report = useSingleSomethingA10('reports', _id, queryId);

  return {
    report,
    loading,
    refetch,
  };
}

export default useReport;
