import { Tabs, Typography } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import Error404 from '../../components/Error404';
import useProfile from '../../shared/use-profile';
import PeopleGroupAdoptionStats from './PeopleGroupAdoptionStats';
import PeopleGroupInfo from './PeopleGroupInfo';
import PeopleGroupPhotos from './PeopleGroupPhotos';
import PeopleGroupTitle from './PeopleGroupTitle';
import PeopleGroupProjects from './holistic-projects/PeopleGroupProjects';
import ReportList from './reports/ReportList';
import useCanCreateUpgQuarterlyReport from './reports/use-can-create-report';
import useMyPeopleGroups from './use-my-pgs';
import usePeopleGroup from './use-people-group';
import useSafeStateA10 from '@aims/shared/shared/use-safe-state-a10';

const { Title, Text } = Typography;

function PeopleGroupDetails() {
  const { _id, tab } = useParams();
  const profile = useProfile();
  const permissions = profile?.permissions;

  const myPgs = useMyPeopleGroups();
  const myPg = myPgs.find((p) => p._id === _id);

  const { data: pg, loading: loading } = usePeopleGroup(_id);

  const showHolistic = useMemo(() => {
    return permissions?.find((p) => p.prefix === 'get-user-h-project');
  }, [permissions]);
  const showUPGStats = useMemo(() => {
    return permissions?.find((p) => p.prefix === 'get-user-upg-stats');
  }, [permissions]);
  const { result: canCreateReport } = useCanCreateUpgQuarterlyReport(_id);

  const history = useHistory();

  const [loadingState, setLoadingState, _loadingState] = useSafeStateA10(0);
  useEffect(() => {
    if (loading) {
      setLoadingState(1);
    } else if (!loading && _loadingState.current === 1) {
      setLoadingState(2);
    }
  }, [loading, _loadingState, setLoadingState]);

  if (loadingState === 2 && !pg) {
    return <Error404 />;
  }

  const name = pg && `${pg.nameAcrossCountries} / ${pg.country}`;

  return (
    <CSPage title={`AIMS.org | ${name || 'View People Group'}`}>
      <CSPageHeader
        titleComponent={
          <PeopleGroupTitle peopleGroup={pg} name={name} roles={myPg?.roles} />
        }
        title={name}
        parents={[
          {
            label: 'Unreached People Groups',
            link: `/upgs`,
          },
        ]}
        style={{ marginBottom: 16 }}
      />
      <Tabs
        defaultActiveKey="info"
        activeKey={tab}
        onChange={(key) => history.push(`/upgs/${_id}/${key}`)}
        size="middle"
      >
        <Tabs.TabPane tab="Info" key="info">
          <PeopleGroupInfo peopleGroupId={_id} details={pg} />
        </Tabs.TabPane>
        {canCreateReport && (
          <Tabs.TabPane tab="Quarterly Reports" key="reports">
            <ReportList peopleGroupId={_id} pgConnection={myPg} />
          </Tabs.TabPane>
        )}
        {showUPGStats && (
          <Tabs.TabPane tab="Quarterly Stats" key="quarterlyStats">
            <PeopleGroupAdoptionStats peopleGroupId={_id} />
          </Tabs.TabPane>
        )}
        {showHolistic && (
          <Tabs.TabPane tab="Holistic Projects" key="holistic-projects">
            <PeopleGroupProjects peopleGroupId={_id} />
          </Tabs.TabPane>
        )}
        <Tabs.TabPane tab="Pictures" key="pictures">
          <PeopleGroupPhotos />
        </Tabs.TabPane>
      </Tabs>
    </CSPage>
  );
}

export default PeopleGroupDetails;
