import { Typography } from 'antd';
import React, { useMemo } from 'react';
import settings from '../../../../settings';

const { Text } = Typography;

function ProjectBubbles({ project }) {
  const bubbles = useMemo(() => {
    if (project) {
      return [
        project.peopleGroupIds &&
          project.peopleGroupIds.length > 0 && {
            text: (
              <div>
                <Text style={{ color: settings.colors.holisticText }}>
                  Grants access to{' '}
                </Text>
                <Text
                  style={{
                    color: settings.colors.white,
                    fontSize: 20,
                    fontWeight: 600,
                  }}
                >
                  {project.peopleGroupIds.length}
                </Text>
                <Text style={{ color: settings.colors.holisticText }}>
                  {' '}
                  Unreached People Groups
                </Text>
              </div>
            ),
          },
        project.numSupported > 0 && {
          text: (
            <div>
              <Text style={{ color: settings.colors.holisticText }}>
                Provids funding for{' '}
              </Text>
              <Text
                style={{
                  color: settings.colors.white,
                  fontSize: 20,
                  fontWeight: 600,
                }}
              >
                {project.numSupported}
              </Text>
              <Text style={{ color: settings.colors.holisticText }}>
                {' '}
                Native Missionaries
              </Text>
            </div>
          ),
        },
        project.region && {
          text: (
            <div>
              <Text style={{ color: settings.colors.holisticText }}>
                Helps bring a gospel movement to{' '}
              </Text>
              <Text
                style={{
                  color: settings.colors.white,
                  fontSize: 20,
                  fontWeight: 600,
                }}
              >
                {project.region}
              </Text>
            </div>
          ),
        },
      ].filter((a) => a);
    }
    return [{}, {}, {}];
  }, [project]);
  return (
    <div className="container">
      {bubbles.map((bubble, index) => (
        <div className="bubble" key={index}>
          {bubble.text}
        </div>
      ))}
      <style jsx>{`
        .container {
          display: flex;
          justify-content: center;
          margin-bottom: 28px;
          margin-right: -12px;
          margin-right: -12px;
          flex-wrap: wrap;
        }
        .bubble {
          background-color: ${settings.colors.holisticLightGreen};
          border-radius: 50%;
          height: 240px;
          width: 240px;
          margin-right: 12px;
          margin-left: 12px;
          margin-bottom: 12px;
          color: white;
          display: flex;
          justifiy-content: center;
          align-items: center;
          text-align: center;
          padding: 32px;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 1.5px;
        }
      `}</style>
    </div>
  );
}

export default ProjectBubbles;
