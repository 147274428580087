import {
  BookOutlined,
  BulbOutlined,
  DollarOutlined,
  FormOutlined,
  FormatPainterOutlined,
  FundProjectionScreenOutlined,
  GiftOutlined,
  HomeOutlined,
  LogoutOutlined,
  MenuOutlined,
  ProjectOutlined,
  ReadOutlined,
  SolutionOutlined,
  TranslationOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Button, Drawer, Layout, Menu, Typography, notification } from 'antd';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import settings from '../settings';
import { AuthContext } from '../shared/auth-context';
import useProfile from '../shared/use-profile';
import MyFooter from './MyFooter';
import longLogo from '../assets/long-logo-sm.png';
import VerifyEmailBanner from './VerifyEmailBanner';
import PhotoCircle from '@aims/shared/components/PhotoCircle';
import sharedSettings from '@aims/shared/sharedSettings';

const { Title, Text } = Typography;

function MainMenu({ currentItem, onMenuClick, horizontal, responsiveMode }) {
  const { t } = useTranslation();

  const profile = useProfile();
  const permissions = profile && profile.role && profile.role.permissions;
  const showPeopleGroups = useMemo(() => {
    return (
      permissions && permissions.includes('user-ui-view-people-groups:all')
    );
  }, [permissions]);
  const showHolistic = useMemo(() => {
    return permissions && permissions.includes('user-ui-view-holistic:all');
  }, [permissions]);
  const showTraining = useMemo(() => {
    return permissions && permissions.includes('user-ui-view-training:all');
  }, [permissions]);
  const showDonations = useMemo(() => {
    return permissions && permissions.includes('user-ui-view-donations:all');
  }, [permissions]);
  const showPartnerPage = useMemo(() => {
    return permissions && permissions.includes('user-ui-view-partner:all');
  }, [permissions]);
  return (
    <Menu
      theme="light"
      mode={horizontal ? 'horizontal' : 'inline'}
      defaultSelectedKeys={[currentItem]}
      onClick={onMenuClick}
      className="main-nav-menu"
      style={{
        width: responsiveMode !== 'mobile' ? 'calc(100vw - 594px)' : undefined,
      }}
    >
      {responsiveMode === 'mobile' && (
        <Menu.Item
          key="edit-profile"
          style={{
            marginBottom: 0,
            height: 48,
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <PhotoCircle
              photoUrl={profile.primaryThumbnailUrl}
              size="sm"
              name={profile.name}
              style={{
                height: 36,
                paddingBottom: 'unset',
                marginRight: 12,
              }}
              color={settings.colors.primary}
            />
            <Link to="/edit-profile">{profile.name}</Link>
          </div>
        </Menu.Item>
      )}
      {showPeopleGroups && (
        <Menu.Item key="home" icon={<HomeOutlined />}>
          <Link to="/home">{t('menu.myPeopleGroups')}</Link>
        </Menu.Item>
      )}
      {showHolistic && (
        <Menu.Item key="projects" icon={<ProjectOutlined />}>
          <Link to="/holistic-projects">{t('menu.myProjects')}</Link>
        </Menu.Item>
      )}
      {showTraining && (
        <Menu.Item key="training" icon={<ReadOutlined />}>
          <Link to="/training">{t('menu.myTraining')}</Link>
        </Menu.Item>
      )}
      {showDonations && (
        <Menu.Item key="donations" icon={<DollarOutlined />}>
          <Link to="/donations">{t('menu.myDonations')}</Link>
        </Menu.Item>
      )}
      {showPartnerPage && (
        <Menu.Item key="partner" icon={<GiftOutlined />}>
          <Link to="/partner">{t('menu.partner')}</Link>
        </Menu.Item>
      )}
      {!horizontal && (
        <>
          <Menu.Divider />
          <Menu.Item key="sign-out" icon={<LogoutOutlined />}>
            {t('menu.signOut')}
          </Menu.Item>
        </>
      )}
    </Menu>
  );
}

function MainNavMenu({ children }) {
  const history = useHistory();
  const location = useLocation();

  const profile = useProfile();

  const showBanner = useMemo(() => {
    if (profile && !profile.emailVerified) {
      return true;
    }
    return false;
  }, [profile]);

  const permissions = profile?.permissions;
  const showUpgs = useMemo(() => {
    return permissions?.find((p) => p.prefix === 'view-user-ui-upg');
  }, [permissions]);
  const showHolistic = useMemo(() => {
    return permissions?.find((p) => p.prefix === 'view-user-ui-holistic');
  }, [permissions]);
  const showTraining = useMemo(() => {
    return permissions?.find((p) => p.prefix === 'view-user-ui-training');
  }, [permissions]);
  const showPartner = useMemo(() => {
    return permissions?.find((p) => p.prefix === 'view-user-ui-partner');
  }, [permissions]);
  const showMou = useMemo(() => {
    return permissions?.find((p) => p.prefix === 'view-user-ui-mou');
  }, [permissions]);

  const items = useMemo(() => {
    return [
      showUpgs && {
        key: '/upgs',
        label: 'Unreached People Groups',
        icon: <TranslationOutlined />,
      },
      showHolistic && {
        key: '/holistic',
        label: 'Holistic Projects',
        icon: <FormatPainterOutlined />,
        children: [
          {
            key: '/holistic-projects',
            label: 'Holistic Projects',
            icon: <FormatPainterOutlined />,
          },
          {
            key: '/holistic-proposals',
            label: 'Holistic Project Proposals',
            icon: <FundProjectionScreenOutlined />,
          },
        ],
      },
      showTraining && {
        key: '/training',
        label: 'Training',
        icon: <BulbOutlined />,
        children: [
          {
            key: '/training/courses',
            label: 'Training Courses & Files',
            icon: <BookOutlined />,
          },
          {
            key: '/training-reports/event',
            label: 'Training Reports',
            icon: <FormOutlined />,
          },
        ],
      },
      showPartner && {
        key: '/partner',
        label: 'Partner with Us',
        icon: <GiftOutlined />,
        children: [
          {
            key: '/partner/adopt-a-upg',
            label: 'Sponsor a Native Missionary',
            icon: <TranslationOutlined />,
          },
          {
            key: '/partner/sponsor-a-project',
            label: 'Fund a Holistic Project',
            icon: <FormatPainterOutlined />,
          },
        ],
      },
      showMou && {
        key: '/mous',
        label: 'Partnership Agreement Forms',
        icon: <SolutionOutlined />,
      },
      {
        key: '/my-profile',
        label: 'My Profile',
        icon: <UserOutlined />,
      },
    ].filter((p) => p);
  }, [showHolistic, showMou, showPartner, showTraining, showUpgs]);

  const [open, setOpen] = useState(false);

  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      {showBanner && <VerifyEmailBanner />}
      <Layout.Header
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: 'white',
          paddingleft: 32,
          paddingRight: 32,
        }}
      >
        <Link to="/home">
          <img
            style={{
              height: 56,
              marginTop: 12,
              marginBottom: 12,
              marginLeft: 16,
              marginRight: 16,
            }}
            src={longLogo}
            alt="AIMS Logo"
          />
        </Link>
        <div style={{ flex: 1 }} />
        <Button icon={<MenuOutlined />} onClick={() => setOpen(true)} />
      </Layout.Header>
      <Layout.Content
        style={{
          background: settings.colors.ghost,
          boxShadow: 'inset 1px 0px 10px rgba(0, 0, 0, 0.05)',
        }}
      >
        <Drawer
          title={
            <Link to="/home">
              <img
                style={{
                  height: 48,
                  marginLeft: -32,
                }}
                src={longLogo}
                alt="AIMS Logo"
              />
            </Link>
          }
          onClose={() => setOpen(false)}
          open={open}
          styles={{
            header: { paddingTop: 12, paddingBottom: 8, textAlign: 'center' },
            body: {
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 8,
              display: 'flex',
              flexDirection: 'column',
            },
          }}
        >
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={[location.pathname]}
            items={items}
            style={{ minWidth: 0 }}
            onClick={(item) => history.push(item.key)}
          />
          <div style={{ flex: 1 }} />
          <Link to="/my-profile">
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <PhotoCircle
                photoUrl={profile.primaryThumbnailUrl}
                size="sm"
                name={profile.name}
                styles={{
                  container: {
                    height: 36,
                    width: 36,
                    marginRight: 12,
                  },
                  placeholder: {
                    backgroundColor: sharedSettings.colors.primary,
                  },
                }}
              />
              <Text
                style={{
                  fontSize: 14,
                  margin: 0,
                  fontWeight: 600,
                  textTransform: 'uppercase',
                }}
              >
                {profile.name}
              </Text>
            </div>
          </Link>
        </Drawer>

        {children}
      </Layout.Content>
      <MyFooter />
    </Layout>
  );
}

export default MainNavMenu;
