import { gql } from '@apollo/client';
import { userProfileAttributes } from '../../auth/constants';

export const publicHolisticProjectAttributes = gql`
  {
    _id
    regionCode
    region
    projectName
    typeOfProject
    desc
    stage
    region
    primaryThumbnailUrl
    numSupported
    fundingTarget
    fundingBalance
    fundingPercentage
    createdAt
    updatedAt
    deletedAt
  }
`;

export const publicHolisticProjectDetailedAttributes = gql`
  {
    _id
    regionCode
    region
    projectName
    typeOfProject
    desc
    stage
    region
    primaryPhoto {
      _id
      images {
        s3Key
        size
        filename
        contentType
        url
        height
        width
        sizeCode
      }
    }
    peopleGroupIds
    peopleGroups {
      _id
      nameAcrossCountries
      primaryThumbnailUrl
    }
    primaryThumbnailUrl
    numSupported
    fundingTarget
    fundingBalance
    fundingPercentage
    createdAt
    updatedAt
    deletedAt
  }
`;

export const selectAProjectToSponsorQuery = gql`
  query SelectAProjectToSponsor(
    $first: Int
    $after: String
    $filters: HolisticProjectFiltersForPublic
    $sortBy: [SortBy]
  ) {
    allHolisticProjectsForPublic(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${publicHolisticProjectAttributes}
      }
    }
  }
`;

export const extractPublicHolisticProjects = (data) =>
  data && data.allHolisticProjectsForPublic;

export const publicHolisticProjectQuery = gql`
  query PublicHolisticProject($_id: ID!) {
    holisticProjectForPublic(_id: $_id) ${publicHolisticProjectDetailedAttributes}
  }
`;

export const extractPublicHolisticProject = (data) =>
  data && data.holisticProjectForPublic;

export const completeOnlineProjectSponsorshipMutation = gql`
  mutation CompleteOnlineProjectSponsorship($paymentId: ID!) {
    completeOnlineProjectSponsorship(paymentId: $paymentId) {
      projectSponsorship {
        _id
        contactId
        sponsorName
        projectId
        projectName
        regionCode
        region
        amount
        recurring
        startDate
        createdAt
        updatedAt
        deletedAt
      }
      profile ${userProfileAttributes}
      project ${publicHolisticProjectDetailedAttributes}
      payment {
        _id
        status
        usdCentsAmount
        paymentMethodType
        payerId
        payerName
        payerEmail
        fundId
        fundName
        ppCreatedOn
        ppUpdatedOn
        ppReceivedOn
        givenOn
        createdAt
        updatedAt
        deletedAt
      }
      recurringPayment {
        _id
        payerId
        payerName
        payerEmail
        amount
        usdCentsAmount
        fundId
        fundName
        adoptionId
        startDate
        frequency
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;
