import ImageWithDefault from '@aims/shared/components/ImageWithDefault';
import { HolisticProjectTypes } from '@aims/shared/holistic-projects/constants';
import { displayMoney, getNumberValue } from '@aims/shared/shared/utils';
import { Button, Card, List, Progress, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import useDefaultProjectPhoto from '../../../holistic-projects/use-default-project-photo';

const { Text, Title, Paragraph } = Typography;

function SelectProjectCard({ project, setShowModal }) {
  // const projectName = `${project.nameAcrossCountries} / ${project.region}`;
  const projectName = `${project.projectName}`;
  const projectType =
    HolisticProjectTypes[project.typeOfProject] &&
    HolisticProjectTypes[project.typeOfProject].label;
  const defaultPhoto = useDefaultProjectPhoto(project);
  return (
    <List.Item>
      <Card
        cover={
          <ImageWithDefault
            imageUrl={project.primaryThumbnailUrl}
            fallbackUrl={defaultPhoto}
            alt={projectName}
            style={{
              objectFit: 'cover',
              width: '100%',
              height: '100%',
              aspectRatio: '1/1',
            }}
          />
        }
      >
        <Title level={5} style={{ marginBottom: 0, whiteSpace: 'normal' }}>
          {projectName}
        </Title>
        {projectType && (
          <div className="right">
            <Text style={{ width: 224 }} ellipsis={{ tooltip: projectType }}>
              {projectType}
            </Text>
          </div>
        )}
        {project.numSupported && (
          <div className="right">
            <Text>
              {`${project.numSupported} Supported ${
                project.numSupported > 1 ? 'Missionaries' : 'Missionary'
              }`}
            </Text>
          </div>
        )}
        <div style={{ marginBottom: 16, marginTop: 16 }}>
          <Progress percent={Math.round(project.fundingPercentage)} />
          <div>
            <Text style={{ fontSize: 16, fontWeight: 600 }}>
              {displayMoney(getNumberValue(project.fundingBalance) / 10000)}
            </Text>{' '}
          </div>
          <div>
            <Text>raised of goal</Text>{' '}
            <Text style={{ fontSize: 16, fontWeight: 600 }}>
              {displayMoney(getNumberValue(project.fundingTarget) / 10000)}
            </Text>
          </div>
        </div>
        {/* {project.region && (
                <div className="right">
                  <Text
                    style={{
                      color: settings.colors.primary,
                      whiteSpace: 'normal',
                    }}
                  >
                    {project.region}
                  </Text>
                </div>
              )} */}
        {/* <div className="right">
                  <Text
                    style={{ width: 224 }}
                    ellipsis={{ tooltip: project.desc }}
                  >
                    {project.desc}
                  </Text>
                </div> */}
        <div
          style={{
            marginTop: 16,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            type="dashed"
            style={{ marginRight: 16 }}
            onClick={() => setShowModal({ project })}
          >
            More Info
          </Button>
          <Link
            to={`/partner/sponsor-a-project/${project._id}${location.search}`}
          >
            <Button type="primary">Select</Button>
          </Link>
        </div>
      </Card>
    </List.Item>
  );
}

export default SelectProjectCard;
