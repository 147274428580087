import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import { displayMoney } from '@aims/shared/shared/utils';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Divider,
  Form,
  InputNumber,
  Typography,
} from 'antd';
import moment from 'moment';
import queryString from 'query-string';
import React, { useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { updateHolisticProjectAction } from '../../../../redux-store/holistic-projects-store';
import settings from '../../../../settings';
import HolisticSteps from '../HolisticSteps';
import {
  extractPublicHolisticProject,
  publicHolisticProjectQuery,
} from '../constants';
import ProjectBanner from './ProjectBanner';
import ProjectBubbles from './ProjectBubbles';
import DateFormItem from '@aims/shared/components/DateFormItem';

const levels = {
  MONTHLY_30: {
    key: 'MONTHLY_30',
    label: '$30 Monthly',
    amount: 30,
  },
  MONTHLY_50: {
    key: 'MONTHLY_50',
    label: '$50 Monthly',
    amount: 50,
  },
  MONTHLY_100: {
    key: 'MONTHLY_100',
    label: '$100 Monthly',
    amount: 100,
  },
  CUSTOM: {
    key: 'CUSTOM',
    label: 'One-Time Donation',
  },
};

const { Title, Paragraph } = Typography;

function SelectALevel({ value, onChange, disabled }) {
  const handleChoice = useCallback(
    (val) => {
      onChange(val);
    },
    [onChange],
  );
  return (
    <div style={{ textAlign: 'center' }}>
      <Title level={4} style={{ marginBottom: 12 }}>
        Select a Funding Level
      </Title>
      <div
        style={{
          marginRight: -8,
          marginLeft: -8,
        }}
      >
        {Object.values(levels).map((level) => (
          <Button
            key={level.key}
            type={value === level.key ? 'primary' : 'normal'}
            onClick={() => handleChoice(level.key)}
            disabled={disabled}
            style={{
              marginRight: 8,
              marginLeft: 8,
              fontSize: 16,
              letterSpacing: 2,
              marginBottom: 8,
            }}
          >
            {level.label}
          </Button>
        ))}
      </div>
    </div>
  );
}

const queryId = 'sponsorAProject';

function SponsorAProjectForm({ step, title, projectId }) {
  const { loading: projectLoading, refetch } = useOneM1({
    variables: { _id: projectId },
    query: publicHolisticProjectQuery,
    extract: extractPublicHolisticProject,
    queryId,
    fetchPolicy: 'network-only',
    updateAction: updateHolisticProjectAction,
    skip: !projectId,
    showError: true,
  });
  const project = useSingleSomethingA10('holisticProjects', projectId, queryId);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const handleFinish = useCallback(
    async (values) => {
      setLoading(true);
      let amount;
      let recurring;
      if (
        [
          levels.MONTHLY_50.key,
          levels.MONTHLY_100.key,
          levels.MONTHLY_30.key,
        ].includes(values.level)
      ) {
        amount = levels[values.level].amount;
        recurring = true;
      } else {
        amount = values.amount;
        recurring = false;
      }
      const link = `/partner/sponsor-a-project/${projectId}/impact?${queryString.stringify(
        {
          amount,
          recurring,
          startDate: values.startDate,
        },
      )}`;
      history.push(link);
    },
    [projectId, history],
  );
  return (
    <>
      <ProjectBanner project={project} loading={projectLoading} />
      <HolisticSteps step={step} title={title} />
      <div>
        <Title level={3} style={{ textAlign: 'center' }}>
          Project Details
        </Title>
        <Paragraph
          style={{ textAlign: 'center', fontSize: 16, marginBottom: 32 }}
        >
          {project && project.desc}
        </Paragraph>
        <ProjectBubbles project={project} />
        <Form layout="vertical" form={form} onFinish={handleFinish}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div
              style={{
                background: settings.colors.white,
                padding: '16px 32px',
                paddingBottom: 8,
                marginBottom: 32,
              }}
            >
              <Form.Item
                name="level"
                rules={[
                  { required: true, message: 'Please select a funding level' },
                ]}
              >
                <SelectALevel disabled={loading} />
              </Form.Item>
              <Form.Item
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.level !== curValues.level
                }
                noStyle
              >
                {({ getFieldValue }) => {
                  if (getFieldValue('level') === 'CUSTOM') {
                    return (
                      <Form.Item
                        label="Amount"
                        name="amount"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter a donation amount',
                          },
                        ]}
                        initialValue={1000}
                        style={{
                          width: 300,
                          marginLeft: 'auto',
                          marginRight: 'auto',
                        }}
                      >
                        <InputNumber
                          addonBefore="$"
                          min={0}
                          step={1}
                          disabled={loading}
                          style={{ width: 300 }}
                        />
                      </Form.Item>
                    );
                  }
                }}
              </Form.Item>
            </div>
          </div>
          <Paragraph
            style={{
              textAlign: 'center',
              fontSize: 14,
            }}
          >
            Your donation will give you access to quarterly reports, prayer
            requests and testimonies for the Unreached People Groups impacted by
            the selected Holistic Project.
          </Paragraph>
          <Divider />
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.level !== curValues.level ||
              prevValues.amount !== curValues.amount
            }
            noStyle
          >
            {({ getFieldValue }) => {
              const levelKey = getFieldValue('level');
              if (levels[levelKey] === undefined) {
                return <Paragraph />;
              }
              let message;
              if (
                [
                  levels.MONTHLY_50.key,
                  levels.MONTHLY_100.key,
                  levels.MONTHLY_30.key,
                ].includes(levelKey)
              ) {
                const amount = levels[levelKey].amount;
                message = `${displayMoney(
                  amount,
                  0,
                )} / Month Recurring Donation`;
              } else {
                const amount = getFieldValue('amount');
                message = `${displayMoney(amount, 0)} One-Time Donation`;
              }
              return (
                <>
                  <Paragraph
                    style={{
                      textAlign: 'center',
                      fontSize: 16,
                    }}
                  >
                    {message}
                  </Paragraph>
                  {[
                    levels.MONTHLY_50.key,
                    levels.MONTHLY_100.key,
                    levels.MONTHLY_30.key,
                  ].includes(levelKey) && (
                    <Paragraph
                      style={{
                        textAlign: 'center',
                        fontSize: 14,
                      }}
                    >
                      Heads up, once a project is fully funded, your monthly
                      donations will be directed to other AIMS Holistic
                      Projects.
                    </Paragraph>
                  )}
                </>
              );
            }}
          </Form.Item>
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.level !== curValues.level
            }
            noStyle
          >
            {({ getFieldValue }) =>
              [
                levels.MONTHLY_50.key,
                levels.MONTHLY_100.key,
                levels.MONTHLY_30.key,
              ].includes(getFieldValue('level')) && (
                <DateFormItem
                  name="startDate"
                  rules={[{ required: true, message: 'Please select a date' }]}
                  label="First Donation"
                  initialValue={new Date().toISOString()}
                  format={'MMMM D, YYYY'}
                />
              )
            }
          </Form.Item>
          <Form.Item>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Link to="/partner/sponsor-a-project" style={{ marginLeft: -12 }}>
                <Button
                  type="text"
                  icon={<ArrowLeftOutlined />}
                  disabled={loading}
                >
                  Back
                </Button>
              </Link>
              <Button
                type="primary"
                htmlType="submit"
                icon={<ArrowRightOutlined />}
                loading={loading}
              >
                Next
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
      <style jsx global>{``}</style>
    </>
  );
}

export default SponsorAProjectForm;
