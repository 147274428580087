import { Layout } from 'antd';
import React from 'react';
import { Route, Switch } from 'react-router';
import PublicNavMenu from '../../components/PublicNavMenu';
import settings from '../../settings';
import AdoptAPeopleGroup from '../partner/adopt-a-upg/AdoptAPeopleGroup';
import PartnerDashboard from '../partner/PartnerDashboard';
import SponsorAProject from '../partner/sponsor-a-project/SponsorAProject';
import AdoptAUpgRedirectToPP from '../partner/adopt-a-upg/AdoptAUpgRedirectToPP';
import AdoptAPgSuccess from '../partner/adopt-a-upg/AdoptAPgSuccess';

function PublicPartnerRouter() {
  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <PublicNavMenu />
      <Layout
        style={{
          background: settings.colors.ghost,
          boxShadow: 'inset 1px 0px 10px rgba(0, 0, 0, 0.05)',
        }}
      >
        <Switch>
          <Route exact path="/partner" component={PartnerDashboard} />
          <Route
            exact
            path="/partner/adopt-a-upg/success"
            component={AdoptAPgSuccess}
          />
          <Route
            exact
            path="/partner/adopt-a-upg/:pgId?"
            component={AdoptAPeopleGroup}
          />
          <Route
            exact
            path="/partner/adopt-a-upg/:pgId/adopt"
            component={AdoptAPeopleGroup}
          />
          <Route
            exact
            path="/partner/adopt-a-upg/:pgId/redirect"
            component={AdoptAUpgRedirectToPP}
          />
          <Route
            exact
            path="/partner/sponsor-a-project/:projectId?/:page?"
            component={SponsorAProject}
          />
        </Switch>
      </Layout>
    </Layout>
  );
}

export default PublicPartnerRouter;
