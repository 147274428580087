import { LoadingOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import moment from 'moment';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Error404 from '../../../components/Error404';
import settings from '../../../settings';
import useAccount from '../../../shared/use-account';
import useProfile from '../../../shared/use-profile';
import useQueryParams from '../../../shared/use-query-params';
import useResponsiveMode from '../../../shared/use-reponsive-mode';
import { generateUuid } from '../../../shared/utils';
import HolisticSteps from './HolisticSteps';

const { Text, Title, Paragraph } = Typography;

function SponsorAProjectRedirectToPP({ title, step }) {
  const { projectId } = useParams();
  const queryParams = useQueryParams();
  const responsive = useResponsiveMode();
  const [error, setError] = useState();
  const profile = useProfile();
  const account = useAccount();

  const handleSponsor = useCallback(
    (values) => {
      // console.log('values', values);
      const { recurring: recurringText, amount } = values;
      const recurring = recurringText === 'true';
      const startDate = values.startDate && moment(values.startDate);
      const notes = JSON.stringify({
        t: recurring ? 'HolisticRecurring' : 'HolisticOneTime',
        sid: generateUuid(),
        uid: profile._id,
        cid: account && account._id,
        pi: projectId === '__aims__' ? undefined : projectId,
        a: amount,
      });
      const params = {
        a: amount,
        al: true,
        r: recurring ? 'monthly' : 'no',
        rcv: false,
        rsd: recurring ? startDate.format('YYYY-MM-DD') : undefined,
        ret: recurring ? 'date' : undefined,
        red: recurring
          ? startDate.add(1, 'year').format('YYYY-MM-DD')
          : undefined,
        fnd: 'Holistic Sustainability',
        fndv: 'hide',
        ufn: profile.name.split(/\s/)[0],
        uln: profile.name.split(/\s/).slice(1).join(' '),
        ue: profile.email,
        up: profile.phone,
        ru: 'sponsor-a-project-success',
        nt: notes,
      };
      const link = `${settings.pushpayGivingUrl}?${queryString.stringify(
        params,
      )}`;
      // console.log('link', link);
      // console.log('note', notes);
      location.href = link;
    },
    [profile, projectId, account],
  );

  const history = useHistory();
  useEffect(() => {
    if (profile && queryParams) {
      const { amount, recurring: recurringText, startDate } = queryParams;
      const recurring = recurringText === 'true';
      let err;
      if (!amount) {
        err = 'Level is required';
      } else if (recurring === undefined) {
        err = 'Recurring is required';
      } else if (recurring && !startDate) {
        err = 'Start date is required';
      }
      if (err) {
        console.error(err);
        setError(err);
        return;
      }
      handleSponsor(queryParams);
    } else if (!profile) {
      const search = `?${queryString.stringify({
        p: location.pathname,
        s: JSON.stringify(queryParams),
      })}`;
      return history.push(`/sign-up${search}`);
    }
  }, [history, profile, queryParams, handleSponsor]);

  if (error) {
    return <Error404 />;
  }

  return (
    <>
      <HolisticSteps step={step} title={title} />
      <Title
        level={2}
        style={{
          marginTop: responsive === 'mobile' ? 0 : 24,
          marginBottom: 8,
          textAlign: 'center',
        }}
      >
        Payment
      </Title>
      <div style={{ textAlign: 'center' }}>
        <div style={{ paddingTop: 32, marginBottom: 16 }}>
          <LoadingOutlined
            style={{ color: settings.colors.primary, fontSize: 64 }}
          />
        </div>
        <Paragraph style={{ fontSize: 16 }}>
          You will be redirected. One moment ...
        </Paragraph>
      </div>
      <style jsx>{``}</style>
    </>
  );
}

export default SponsorAProjectRedirectToPP;
