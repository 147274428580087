import { Button, Empty, List, Typography } from 'antd';
import React, { useState } from 'react';

import useMyPeopleGroups from '../people-groups/use-my-pgs';
import AllProjectsListItem from './AllProjectsListItem';
import { useAllHolisticProjects } from './use-all-holistic-projects';

const { Title, Text } = Typography;

function AllProjectsList() {
  const [numShown, setNumShown] = useState(24);

  const items = useMyPeopleGroups();
  const pgIds = items.map((i) => i.peopleGroup._id);

  const { data: holisticProjects, loading } = useAllHolisticProjects({
    filters: { peopleGroupIds: pgIds },
    queryId: 'peopleGroupProjects',
    skip: pgIds.length === 0,
  });

  return (
    <>
      <List
        className="recentList"
        dataSource={holisticProjects.slice(0, numShown)}
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 3,
          lg: 3,
          xl: 4,
          xxl: 4,
        }}
        rowKey="_id"
        renderItem={(item) => (
          <AllProjectsListItem holisticProject={item} loading={loading} />
        )}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Holistic Projects"
            />
          ),
        }}
        loadMore={
          holisticProjects.length > numShown && (
            <div className="load-more">
              <Button onClick={() => setNumShown(numShown + 8)}>
                Load More
              </Button>
            </div>
          )
        }
      />
    </>
  );
}

export default AllProjectsList;
