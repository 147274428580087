import { useMutation } from '@apollo/client';
import { Button, Typography } from 'antd';
import { displayMoney } from '@aims/shared/shared/utils';
import React, { useEffect, useMemo, useState } from 'react';
import Confetti from 'react-dom-confetti';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import { authStateChangedAction } from '../../../redux-store/auth-store';
import { createProjectSponsorshipAction } from '../../../redux-store/project-sponsorships-store';
import settings from '../../../settings';
import useQueryParams from '../../../shared/use-query-params';
import { useWindowDimensions } from '../../../shared/window-dimensions';
import { PaymentFrequencyTypes } from '../adopt-a-upg/constants';
import { completeOnlineProjectSponsorshipMutation } from './constants';
import MyPhotoCircle from '../../../components/MyPhotoCircle';
import useDefaultProjectPhoto from '../../holistic-projects/use-default-project-photo';
import { HolisticProjectTypes } from '@aims/shared/holistic-projects/constants';
import { CheckCircleFilled } from '@ant-design/icons';

const { Title, Text, Paragraph } = Typography;

function SponsorAProjectSuccess() {
  const { paymentToken } = useQueryParams();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [completeOnlineProjectSponsorship] = useMutation(
    completeOnlineProjectSponsorshipMutation,
  );
  const dispatch = useDispatch();
  const [result, setResult] = useState();
  const [doConfetti, setDoConfetti] = useState();

  useEffect(() => {
    async function doAsyncStuff() {
      setLoading(true);
      setError(null);
      try {
        if (paymentToken) {
          const _result = await completeOnlineProjectSponsorship({
            variables: {
              paymentId: paymentToken,
            },
          });
          dispatch(
            authStateChangedAction(
              _result.data.completeOnlineProjectSponsorship.profile,
            ),
          );
          dispatch(
            createProjectSponsorshipAction(
              _result.data.completeOnlineProjectSponsorship.projectSponsorship,
            ),
          );
          setResult(_result.data.completeOnlineProjectSponsorship);
          setTimeout(() => {
            setDoConfetti(true);
          }, 250);
        }
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    }
    doAsyncStuff();
  }, [paymentToken, completeOnlineProjectSponsorship, dispatch]);

  const project = useMemo(() => {
    if (result && result.project) {
      return result.project;
    }
    return undefined;
  }, [result]);

  const locale = useSelector((store) => store.locale, shallowEqual);

  const yourDonation = useMemo(() => {
    if (result) {
      if (result.recurringPayment) {
        const frequencyLabel =
          PaymentFrequencyTypes[result.recurringPayment.frequency] &&
          PaymentFrequencyTypes[result.recurringPayment.frequency].label;
        return `${displayMoney(
          result.recurringPayment.usdCentsAmount / 100,
        )} ${frequencyLabel} starting ${new Intl.DateTimeFormat(locale, {
          dateStyle: 'full',
        }).format(new Date(result.recurringPayment.startDate))}`;
      } else if (result.payment) {
        return `One-time ${displayMoney(result.payment.usdCentsAmount / 100)}`;
      }
    }
  }, [result, locale]);

  const windowDims = useWindowDimensions();
  const projectType =
    project &&
    HolisticProjectTypes[project.typeOfProject] &&
    HolisticProjectTypes[project.typeOfProject].label;
  const defaultPhoto = useDefaultProjectPhoto(project);

  return (
    <CSPage title="AIMS.org | Fund a Holistic Project Success">
      <CSPageHeader
        title="Success"
        parents={[{ label: 'Partner' }, { label: 'Fund a Holistic Project' }]}
      />
      {loading && (
        <div>
          <Text>{`We're processing your donation.  One moment ...`}</Text>
        </div>
      )}
      {error && (
        <div style={{ textAlign: 'center' }}>
          <Title type="danger" level={4}>
            Error
          </Title>
          <Text type="danger">{error}</Text>
        </div>
      )}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Confetti
          active={doConfetti}
          config={{
            angle: 90,
            spread: 360,
            startVelocity: 40,
            elementCount: 70,
            dragFriction: 0.12,
            duration: 3000,
            stagger: 3,
            width: '10px',
            height: '10px',
            perspective: '500px',
            colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
          }}
        />
      </div>
      <div
        style={{
          textAlign: 'center',
          margin: 'auto',
          maxWidth: 1000,
        }}
      >
        {!loading && !error && project && (
          <>
            <Title style={{ marginBottom: 32 }}>
              Thank you for partnering with us!
            </Title>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexWrap: 'wrap',
              }}
            >
              <div style={{ flex: 1, marginRight: 16, marginLeft: 16 }}>
                <div
                  style={{
                    background: 'white',
                    borderRadius: 16,
                    boxShadow: `0px 0px 8px ${settings.colors.borderGray}`,
                  }}
                  className="pg-card"
                >
                  <div className="pg-photo">
                    <MyPhotoCircle
                      imageUrl={project.primaryThumbnailUrl || defaultPhoto}
                      initials={project.projectName}
                      diameter={160}
                      color={settings.colors.yellow}
                      textStyle={{ color: 'white' }}
                    />
                  </div>
                  <Title level={4}>{project.projectName}</Title>
                  <Paragraph style={{ marginBottom: 0, fontSize: 14 }}>
                    {projectType}
                  </Paragraph>
                  <Paragraph style={{ marginBottom: 0, fontSize: 14 }}>
                    {project.region}
                  </Paragraph>
                  <Paragraph style={{ marginBottom: 0, fontSize: 14 }}>
                    {`${project.numSupported} Supported ${
                      project.numSupported > 1 ? 'Missionaries' : 'Missionary'
                    }`}
                  </Paragraph>
                  <Paragraph style={{ marginBottom: 0, fontSize: 14 }}>
                    {`${project.peopleGroupIds.length} Impacted ${
                      project.peopleGroupIds.length > 1
                        ? 'Unreached People Groups'
                        : 'Unreached People Group'
                    }`}
                  </Paragraph>
                </div>
                <Title
                  level={3}
                  style={{ marginTop: 32, marginBottom: 4 }}
                >{`What's next?`}</Title>
                <Paragraph style={{ fontSize: 14 }}>
                  An email containing more details about your holistic project
                  will be sent shortly. Take a moment to create an account to
                  track the impact among the Unreached People Group(s) connected
                  with this project.
                </Paragraph>
              </div>
              <div
                style={{
                  flex: 2,
                  marginRight: 16,
                  marginLeft: 16,
                  textAlign: 'left',
                }}
              >
                <div
                  style={{
                    background: 'white',
                    borderRadius: 16,
                    boxShadow: `0px 0px 8px ${settings.colors.borderGray}`,
                    display: 'block',
                    marginBottom: 32,
                  }}
                  className="pg-card"
                >
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <div style={{ marginRight: 24, marginBottom: 16 }}>
                      <CheckCircleFilled
                        style={{
                          color: settings.colors.holisticGreen,
                          fontSize: 64,
                        }}
                      />
                    </div>
                    <div style={{ flex: 1, minWidth: 200 }}>
                      <Title level={2} style={{ marginBottom: 4 }}>
                        Donation Successful
                      </Title>
                      <Paragraph style={{ fontSize: 14 }}>
                        Thank you. Your donation was successful and is being
                        processed.
                      </Paragraph>
                      <Title level={3} style={{ marginBottom: 4 }}>
                        Your Gift
                      </Title>
                      <Paragraph style={{ fontSize: 14 }}>
                        {yourDonation}
                      </Paragraph>
                    </div>
                  </div>
                </div>
                <Paragraph style={{ fontSize: 14, marginBottom: 32 }}>
                  When you create an account, you’ll gain access to an online
                  dashboard where you can view quarterly reports and prayer
                  requests from the native missionaries supported by this
                  holistic project.
                </Paragraph>
                <div>
                  <Link to="/holistic-projects/projects">
                    <Button
                      style={{
                        width: '100%',
                        fontSize: 20,
                        textTransform: 'uppercase',
                        padding: 0,
                      }}
                      type="primary"
                    >
                      Continue to My Dashboard
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
            <div style={{ height: 24 }} />
          </>
        )}
      </div>
      <style jsx>{`
        .pg-photo {
          margin-bottom: 16px;
        }
        .pg-card {
          padding: 32px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .right {
          text-align: center;
          font-size: 14px;
          margin-bottom: 4px;
        }
      `}</style>
      <style jsx global>{`
        h1.ant-typography {
          color: black;
          letter-spacing: 2px;
          font-weight: 600;
        }
        h2.ant-typography {
          color: ${settings.colors.holisticText};
          letter-spacing: 2px;
          font-weight: 600;
        }
        h3.ant-typography {
          color: ${settings.colors.holisticText};
          letter-spacing: 1.5px;
          font-weight: 600;
        }
        h4.ant-typography {
          color: ${settings.colors.holisticText};
          letter-spacing: 1.5px;
          font-weight: 600;
        }
        h5.ant-typography {
          color: ${settings.colors.holisticText};
        }
        .ant-btn.ant-btn-normal {
          background-color: #dcdcdc;
          border-color: #dcdcdc;
        }
        .ant-btn.ant-btn-normal:hover {
          color: ${settings.colors.holisticDarkGreen};
        }
        .ant-btn.ant-btn-primary {
          background-color: ${settings.colors.holisticGreen};
          border-color: ${settings.colors.holisticGreen};
        }
      `}</style>
    </CSPage>
  );
}

export default SponsorAProjectSuccess;
