import {
  HolisticProjectStates,
  HolisticProjectTypes,
} from '@aims/shared/holistic-projects/constants';
import { Tabs, Typography } from 'antd';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import settings from '../../settings';
import useProfile from '../../shared/use-profile';
import ProjectInfo from './ProjectInfo';
import ProjectLoanAgreementsTab from './ProjectLoanAgreementsTab';
import ProjectPhotos from './ProjectPhotos';
import ViewProposalTab from './proposals/ViewProposalTab';
import ReportList from './reports/ReportList';
import useDefaultProjectPhoto from './use-default-project-photo';
import useHolisticProjectForAdopter from './use-holistic-project-for-adopter';
import PhotoCircle from '@aims/shared/components/PhotoCircle';
import useCanCreateHolisticQuarterlyReport from './reports/use-can-create-report';

const { Title, Text } = Typography;

function ViewHolisticProject() {
  const { _id, tab } = useParams();
  const profile = useProfile();

  const { loading, data: holisticProject } = useHolisticProjectForAdopter(
    _id,
    'viewHolisticProject',
  );
  const { result: canCreateReport } = useCanCreateHolisticQuarterlyReport(_id);

  const photoUrl = holisticProject && holisticProject?.primaryThumbnailUrl;
  const defaultPhoto = useDefaultProjectPhoto(holisticProject);

  const history = useHistory();

  return (
    <>
      <CSPage
        title={`AIMS.org | ${
          holisticProject?.projectName || 'View Holistic Project'
        }`}
      >
        <CSPageHeader
          titleComponent={
            <div style={{ display: 'flex', paddingTop: 24 }}>
              <PhotoCircle
                photoUrl={photoUrl || defaultPhoto}
                name={holisticProject?.projectName}
                styles={{
                  container: { height: 148, width: 148, marginRight: 32 },
                  placeholder: {
                    backgroundColor: settings.colors.yellow,
                    fontSize: 24,
                  },
                }}
              />
              <div>
                <Title level={5}>
                  {HolisticProjectTypes[holisticProject?.typeOfProject]?.label}
                </Title>
                <Title
                  level={1}
                  style={{
                    marginBottom: holisticProject?.status ? 0 : undefined,
                    marginTop: 0,
                  }}
                >
                  {holisticProject?.projectName}
                </Title>
                {holisticProject?.status && (
                  <div style={{ marginBottom: 8 }}>
                    <Text
                      style={{
                        color: settings.colors.textGray,
                        fontSize: 14,
                      }}
                    >
                      {HolisticProjectStates[holisticProject?.status]?.label}
                    </Text>
                  </div>
                )}
                <div style={{ marginBottom: 8 }}>
                  {holisticProject?.peopleGroups?.map((pg) => (
                    <div key={pg._id}>
                      <Text>{`${pg.nameAcrossCountries} - ${pg.country} (${pg._id})`}</Text>
                    </div>
                  ))}
                </div>
                {holisticProject?.numSupported >= 1 && (
                  <div style={{ marginBottom: 8 }}>
                    <Text>
                      Supported Field Workers:{' '}
                      {holisticProject?.numSupported || 'none'}
                    </Text>
                  </div>
                )}
              </div>
            </div>
          }
          title={holisticProject?.projectName}
          parents={[
            {
              label: 'Holistic Projects',
              link: `/holistic-projects`,
            },
          ]}
        />
        <Tabs
          defaultActiveKey="info"
          activeKey={tab}
          onChange={(key) => history.push(`/holistic-projects/${_id}/${key}`)}
          size="middle"
        >
          <Tabs.TabPane tab="Info" key="info">
            <ProjectInfo projectId={_id} project={holisticProject} />
          </Tabs.TabPane>
          {canCreateReport && (
            <Tabs.TabPane tab="Reports" key="reports">
              <ReportList projectId={_id} user={profile} />
            </Tabs.TabPane>
          )}
          <Tabs.TabPane tab="Pictures" key="pictures">
            <ProjectPhotos />
          </Tabs.TabPane>
          {holisticProject &&
            holisticProject?.applicationId &&
            canCreateReport && (
              <Tabs.TabPane tab="Proposal" key="proposal">
                <ViewProposalTab reportId={holisticProject?.applicationId} />
              </Tabs.TabPane>
            )}
          {canCreateReport && (
            <Tabs.TabPane tab="Loan Agreements" key="loan-agreements">
              <ProjectLoanAgreementsTab
                projectId={_id}
                project={holisticProject}
              />
            </Tabs.TabPane>
          )}
        </Tabs>
      </CSPage>
    </>
  );
}

export default ViewHolisticProject;
