import { gql } from '@apollo/client';
import { Modal, Typography } from 'antd';
import * as React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import client from '../apollo-client';
import { myProfileForUserQuery } from '../pages/auth/constants';
import {
  authStateChangedAction,
  signOutAction,
} from '../redux-store/auth-store';
import settings from '../settings';
import { isEmptyChildren, isFunction } from './react-utils';

const { Text } = Typography;

const signIntoUserMutation = gql`
  mutation SignIntoUser($username: String!, $password: String!) {
    signIntoUser(username: $username, password: $password) {
      token
    }
  }
`;

const signIntoUserWithGoogleMutation = gql`
  mutation signIntoUserWithGoogle(
    $authResponseCode: String!
    $origin: String!
  ) {
    signIntoUserWithGoogle(
      authResponseCode: $authResponseCode
      origin: $origin
    ) {
      token
    }
  }
`;

const signInWithMagicLinkMutation = gql`
  mutation signIntoUserWithMagicLink($code: String!) {
    signIntoUserWithMagicLink(code: $code) {
      token
    }
  }
`;

const signUpForUserMutation = gql`
  mutation SignUpForUser($signUp: UserSignUpInput!) {
    signUpForUser(signUp: $signUp) {
      token
    }
  }
`;

const initialAuthState = {
  isLoading: true,
  isSignout: false,
  user: null,
};

export const AuthContext = React.createContext({
  signIn: async () => '',
  signOut: () => null,
  signUp: async () => '',
  state: initialAuthState,
});

function processErrorMessage(err) {
  console.error(err);
  if (err && err.message.includes('has not been assigned a role')) {
    Modal.info({
      title: 'Please contact your administrator',
      content: (
        <Text>{`The system recognizes your account, but your profile hasn't yet been assigned a role.  Please contact your administrator at admin@aims.org.`}</Text>
      ),
      onOk() {},
    });
    return undefined;
  }
  if (err && err.message.includes('does not exist')) {
    return 'Username and/or password incorrect';
  }
  return err && err.message;
}

async function finishSignIn(token, dispatch) {
  localStorage.setItem('authToken', token);
  const response = await client.query({
    query: myProfileForUserQuery,
    fetchPolicy: 'network-only',
  });
  const profile = response?.data?.myProfileForUser;
  if (!profile) {
    throw new Error('Error trying to sign in');
  }
  if (profile.myHousehold) {
    localStorage.setItem('contactId', profile.myHousehold._id);
  } else {
    localStorage.setItem('contactId', profile.myContact._id);
  }
  dispatch(authStateChangedAction(response.data.myProfileForUser));
}

function killSignIn(dispatch) {
  localStorage.removeItem('authToken');
  localStorage.removeItem('contactId');
  dispatch(authStateChangedAction(null));
  client.close();
}

export const AuthContextProvider = (props) => {
  const { component, children } = props;

  const dispatch = useDispatch();
  const authState = useSelector((store) => store.authState, shallowEqual);
  const currentUser = useSelector((store) => store.profile, shallowEqual);
  const state = React.useMemo(
    () => ({
      ...authState,
      user: currentUser,
    }),
    [authState, currentUser],
  );

  React.useEffect(() => {
    const doAsyncStuff = async () => {
      const token = localStorage.getItem('authToken');
      try {
        if (!token) {
          throw new Error('Not signed in');
        }
        await finishSignIn(token, dispatch);
      } catch (err) {
        console.error(err);
        killSignIn(dispatch);
      }
    };
    doAsyncStuff();
  }, [dispatch]);

  const authContext = React.useMemo(
    () => ({
      signIn: async (values) => {
        const { username, password } = values;
        try {
          let response = await client.mutate({
            mutation: signIntoUserMutation,
            variables: {
              username: username,
              password: password,
            },
            fetchPolicy: 'no-cache',
          });
          const token = response?.data?.signIntoUser?.token;
          if (!token) {
            throw new Error('Error trying to sign in');
          }
          await finishSignIn(token, dispatch);
          client.close();
        } catch (err) {
          killSignIn(dispatch);
          return processErrorMessage(err);
        }
        return '';
      },
      signIntoUserWithGoogle: async (authResponseCode) => {
        try {
          let response = await client.mutate({
            mutation: signIntoUserWithGoogleMutation,
            variables: {
              authResponseCode,
              origin: window.location.origin,
            },
            fetchPolicy: 'no-cache',
          });
          const token = response?.data?.signIntoUserWithGoogle?.token;
          if (!token) {
            throw new Error('Error trying to sign in');
          }
          await finishSignIn(token, dispatch);
          client.close();
        } catch (err) {
          killSignIn(dispatch);
          return processErrorMessage(err);
        }
        return '';
      },
      signInWithMagicLink: async (code) => {
        try {
          let response = await client.mutate({
            mutation: signInWithMagicLinkMutation,
            variables: {
              code,
            },
            fetchPolicy: 'no-cache',
          });
          const token = response?.data?.signIntoUserWithMagicLink?.token;
          if (!token) {
            throw new Error('Error trying to sign in');
          }
          finishSignIn(token, dispatch);
          client.close();
        } catch (err) {
          killSignIn(dispatch);
          return processErrorMessage(err);
        }
        return '';
      },
      signOut: async () => {
        killSignIn(dispatch);
        dispatch(signOutAction());
      },
      signUp: async (values) => {
        try {
          await new Promise((resolve) => grecaptcha.ready(() => resolve()));
          const recaptcha = await grecaptcha.execute(
            settings.recaptchaPublicKey,
            {
              action: 'submit',
            },
          );
          let response = await client.mutate({
            mutation: signUpForUserMutation,
            variables: {
              signUp: {
                ...values,
                recaptcha,
              },
            },
            fetchPolicy: 'no-cache',
          });
          const token = response?.data?.signUpForUser?.token;
          if (!token) {
            throw new Error('There was an error creating your account');
          }
          await finishSignIn(token, dispatch);
          client.close();
        } catch (err) {
          killSignIn(dispatch);
          return processErrorMessage(err);
        }
        return '';
      },
      state,
    }),
    [state, dispatch],
  );

  return (
    <AuthContext.Provider value={authContext}>
      {component
        ? React.createElement(component, authContext)
        : children // children come last, always called
        ? isFunction(children)
          ? children(authContext)
          : !isEmptyChildren(children)
          ? React.Children.only(children)
          : null
        : null}
    </AuthContext.Provider>
  );
};
