import { Typography } from 'antd';
import React from 'react';
import bannerImgLg from '../../../assets/HolisticCatalogBanner-lg.jpeg';
import bannerImgMd from '../../../assets/HolisticCatalogBanner-md.jpeg';
import bannerImgSm from '../../../assets/HolisticCatalogBannerZoomedSm.png';
import settings from '../../../settings';

const { Text, Title, Paragraph } = Typography;

function CatalogBannerImage() {
  return (
    <>
      <img
        className="banner-img"
        src={bannerImgLg}
        srcSet={`${bannerImgSm} 1000w,
        ${bannerImgMd} 2000w,
        ${bannerImgLg} 3000w`}
        sizes={`(max-width: ${settings.screenSizes.md600}px) 1000w,
        (max-width: ${settings.screenSizes.lg992}px) 2000w,
            3000w`}
      />
      <style jsx>{`
        .banner-img {
          width: 100%;
          margin-bottom: 32px;
          max-width: 3000px;
          margin-right: auto;
          margin-left: auto;
        }
      `}</style>
    </>
  );
}

export default CatalogBannerImage;
