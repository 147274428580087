import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import { gql } from '@apollo/client';
import { updateHolisticProjectAction } from '../../redux-store/holistic-projects-store';

const query = gql`
  query HolisticProjectPhotosForUser($_id: ID!) {
    holisticProjectForUser(_id: $_id) {
      _id
      applicationId
      projectName
      typeOfProject
      desc
      status
      photos {
        _id
        desc
        s3Key
        size
        filename
        contentType
        tags
        url
        uploaded
        processed
        thumbnail {
          s3Key
          size
          filename
          contentType
          url
          height
          width
          sizeCode
        }
        images {
          s3Key
          size
          filename
          contentType
          url
          height
          width
          sizeCode
        }
        attachedTo
        profilePhotoFor
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

const extract = (data) => data.holisticProjectForUser;

function useHolisticProjectPhotos(_id, queryId = 'default') {
  const { loading, refetch } = useOneM1({
    variables: { _id },
    query,
    extract,
    queryId,
    fetchPolicy: 'network-only',
    updateAction: updateHolisticProjectAction,
    skip: !_id,
    showError: true,
  });
  const data = useSingleSomethingA10('holisticProjects', _id, queryId);

  return {
    data,
    loading,
    refetch,
  };
}

export default useHolisticProjectPhotos;
