import ImageWithDefault from '@aims/shared/components/ImageWithDefault';
import { HolisticProjectTypes } from '@aims/shared/holistic-projects/constants';
import { displayMoney, getNumberValue } from '@aims/shared/shared/utils';
import { Progress, Skeleton, Typography } from 'antd';
import React, { useMemo } from 'react';
import settings from '../../../../settings';
import useDefaultProjectPhoto from '../../../holistic-projects/use-default-project-photo';
import { useWindowDimensions } from '../../../../shared/window-dimensions';

const { Title, Text } = Typography;

function ProjectBanner({ project }) {
  const images = useMemo(() => {
    if (project && project.primaryPhoto && project.primaryPhoto.images) {
      const imgLg = project.primaryPhoto.images.find((p) => p.sizeCode == 'LG');
      const imgMd = project.primaryPhoto.images.find((p) => p.sizeCode == 'MD');
      const imgSm = project.primaryPhoto.images.find((p) => p.sizeCode == 'SM');
      return {
        bannerImgLg: imgLg.url,
        bannerImgMd: imgMd.url,
        bannerImgSm: imgSm.url,
      };
    }
    return undefined;
  }, [project]);
  const projectType =
    project &&
    HolisticProjectTypes[project.typeOfProject] &&
    HolisticProjectTypes[project.typeOfProject].label;
  const defaultPhoto = useDefaultProjectPhoto(project);
  const { bannerImgLg, bannerImgMd, bannerImgSm } = images || {};
  const dimensions = useWindowDimensions();
  return (
    <div className="container">
      {images ? (
        <img
          className="banner-img"
          src={bannerImgLg}
          srcSet={`${bannerImgSm} 720w,
        ${bannerImgMd} 1080w,
        ${bannerImgLg} 1920w`}
          sizes={`(max-width: ${settings.screenSizes.md600}px) 720w,
        (max-width: ${settings.screenSizes.lg992}px) 1080w,
        1920w`}
        />
      ) : (
        <div
          className="banner-img"
          style={{
            background: `linear-gradient(to right, ${settings.colors.holisticDarkGreen}, ${settings.colors.holisticGreen})`,
          }}
        />
      )}

      <div className="card">
        <div className="row1">
          {dimensions.width >= 840 && (
            <ImageWithDefault
              imageUrl={project && project.primaryThumbnailUrl}
              fallbackUrl={defaultPhoto}
              alt={project && project.projectName}
              style={{
                objectFit: 'cover',
                height: 240,
                aspectRatio: '1/1',
              }}
            />
          )}
          {project ? (
            <div className="right">
              <Title level={3} style={{ marginBottom: 0 }}>
                {project.projectName}
              </Title>
              {projectType && (
                <div
                  style={{
                    fontSize: 18,
                  }}
                >
                  {projectType}
                </div>
              )}
              {project.region && (
                <div>
                  <Text
                    style={{
                      color: settings.colors.holisticText,
                      fontSize: 16,
                    }}
                  >
                    {project.region}
                  </Text>
                </div>
              )}
              {/* {project.numSupported && (
                <div style={{ marginBottom: 8 }}>
                  <Text>
                    {`${project.numSupported} Supported ${
                      project.numSupported > 1 ? 'Missionaries' : 'Missionary'
                    }`}
                  </Text>
                </div>
              )} */}
              <div className="row2">
                <Progress percent={Math.round(project.fundingPercentage)} />
                <div style={{ marginTop: 4 }}>
                  <Text style={{ fontSize: 16, fontWeight: 600 }}>
                    {displayMoney(
                      getNumberValue(project.fundingBalance) / 10000,
                    )}
                  </Text>{' '}
                  <Text>raised of goal</Text>{' '}
                  <Text style={{ fontSize: 16, fontWeight: 600 }}>
                    {displayMoney(
                      getNumberValue(project.fundingTarget) / 10000,
                    )}
                  </Text>
                </div>
              </div>
            </div>
          ) : (
            <Skeleton active />
          )}
        </div>
      </div>

      <style jsx>{`
        .container {
          position: relative;
          margin-bottom: 32px;
        }
        .banner-img {
          object-fit: cover;
          max-width: 3000px;
          margin-right: auto;
          margin-left: auto;
          aspect-ratio: 7 / 2;
          width: 100%;
          min-height: 240px;
        }
        .card {
          background: white;
          position: absolute;
          bottom: 0px;
          left: 10%;
          min-width: 50%;
        }
        @media (max-width: 840px) {
          .card {
            position: static;
          }
        }
        .row1 {
          display: flex;
        }
        .row2 {
          margin-top: 16px;
          margin-bottom: 16px;
          text-align: left;
        }
        .right {
          padding: 32px;
          display: flex;
          justify-content: left;
          align-items: left;
          flex-direction: column;
          flex: 1;
        }
      `}</style>
    </div>
  );
}

export default ProjectBanner;
